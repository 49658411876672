import React from 'react';
import snapStayLogo from './icon.png';
import appStoreLogo from './App-Symbol.png';
import { useNavigate } from 'react-router-dom';


function HomePage() {
    const navigate = useNavigate();

    return (
        <header className="App-header">
            <img src={snapStayLogo} className="App-logo" alt="snapstayLogo" />
            <div className='SnapStayName'>SnapStay</div>
            <div className='snapStaySubtitle'>enhancing nightlife experience </div>
            <a href="https://apps.apple.com/ca/app/snapstay/id6467181201">
            <img src={appStoreLogo} className="appStore" alt="app-store-logo" />
            </a>
            <div className='legal'>
            <div className='snapstayPolicy' >
            <a  className='snapstayPolicy' href="mailto:patrick@snapstay.co">Email Us</a>
            </div>
            <div className='snapstayPolicy'
            onClick={() => navigate('/faq')}
            >
                FAQ
            </div>
            <div className='snapstayPolicy'
            onClick={() => navigate('/privacy')}
            >
                Privacy & Terms
            </div>
            <div className='snapstayPolicy'
            onClick={() => navigate('/Safety')}
            >
                Safety policy
            </div>
            </div>
            {/*<div className='snapstayPolicy'*/}
            {/*     onClick={() => navigate('/faq')}*/}
            {/*>*/}
            {/*    FAQ*/}
            {/*</div>*/}
            {/*<div className='snapstayPolicy'*/}
            {/*     onClick={() => navigate('/safety')}*/}
            {/*>*/}
            {/*    Safety*/}
            {/*</div>*/}
        </header>
    );
}

export default HomePage;
