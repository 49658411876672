import { ACTION } from "../strings";

const initialState = {
    eventName: null,
    clipUrl: null,
    date: null,
    time: null,
    capacity: null,
    datePicker: null,
    description: false,
    price: null,
    address: null,
    city: null,
    postalCode: null,
    lat: null,
    lng: null,
    images: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.EVENT_NAME: 
        return{
            ...state, 
            eventName: action.data
        }
        case ACTION.LISTING_IMAGES:
                return {
                    ...state,
                    images: action.data
                };
        case ACTION.CLIP_URL:
            return {
                ...state,
                clipUrl: action.data
            };
        case ACTION.DATE:
            return {
                ...state,
                date: action.date
            };

        case ACTION.TIME:
            return {
                ...state,
                time: action.data
            };
        case ACTION.EVENT_CAPACITY:
            return {
                ...state,
                capacity: action.data
            };
        case ACTION.EVENT_DATE_PICKER:
            return {
                ...state,
                datePicker: action.data
            };
        case ACTION.EVENT_DESCRIPTION:
            return {
                ...state,
                description: action.data
            };
        case ACTION.EVENT_PRICE:
            return {
                ...state,
                price: action.data
            };
        case ACTION.EVENT_ADDRESS:
            return {
                ...state,
                address: action.data
            };
        case ACTION.EVENT_POSTAL_CODE:
            return {
                ...state,
                postalCode: action.data
            };
        case ACTION.EVENT_CITY:
            return {
                ...state,
                city: action.data
            };
        case ACTION.EVENT_LAT:
            return {
                ...state,
                lat: action.data
            };
        case ACTION.EVENT_LNG:
            return {
                ...state,
                lng: action.data
            };
        default:
            return state;
    }
};
export default reducer;
