export const ACTION = {
LISTING_IMAGES: "LISTING_IMAGES",
RESET_LISTING_IMAGES: "RESET_LISTING_IMAGES",
EVENT_NAME: "EVENT_NAME",
CLIP_URL: "CLIP_URL",
DATE: "DATE",
TIME: "TIME",
EVENT_CAPACITY: "EVENT_CAPACITY",
EVENT_DATE_PICKER: "EVENT_DATE_PICKER",
EVENT_DESCRIPTION: "EVENT_DESCRIPTION",
EVENT_PRICE: "EVENT_PRICE",
EVENT_ADDRESS: "EVENT_ADDRESS",
EVENT_CITY: "EVENT_CITY",
EVENT_POSTAL_CODE: "EVENT_POSTAL_CODE",
EVENT_LAT: "EVENT_LAT",
EVENT_LNG: "EVENT_LNG",
};
