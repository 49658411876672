import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivacyPolicy from './privacyPolicy'; 
import HomePage from './home';
import Safety from './components/FaqAndSafety/Safety';
import FAQ from './components/FaqAndSafety/faq';
import Privacy from './components/FaqAndSafety/privacy';


function App() {
    return (
        <Router>
        <div className="App">
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                <Route path="/" element={<HomePage />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/safety" element={<Safety />} />
                <Route path="/privacy" element={<Privacy />} />

                {/* It's a good idea to have a catch-all route as well */}
                <Route path="*" element={<div>404 Not Found</div>} />
            </Routes>
        </div>
    </Router>
    );
}

export default App;
