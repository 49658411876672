// src/components/Safety.js
import React from 'react';
import './safety.css';

const Safety = () => {
  return (
    <div className='safety'>
      <div class="section1">
        <h1>Safety Center</h1>
        <h2>Welcome to the SnapStay Safety Center</h2>
        <p>At SnapStay, your safety and privacy are our top priorities. We've developed this Safety Center to provide you with the resources and tools you need to enjoy SnapStay securely and with peace of mind. Here, you'll find information on how we protect you, and steps you can take to safeguard your experience.</p>
        <br />
        <h2>Our Commitment to Security</h2>
        <p>SnapStay employs state-of-the-art security measures to protect your information and ensure a secure user experience. From advanced encryption to regular security audits, we're dedicated to keeping the app safe from unauthorized access and potential threats.</p>
        <h2>Privacy Protection</h2>
        <p>Your privacy matters. In SnapStay, your personal details and activities are guarded with the utmost care, ensuring they remain confidential and secure. We adhere to strict data protection policies to safeguard your privacy.</p>
        <h2>Safe Connections</h2>
        <p>Discover and connect with clubs and stylists without worry. We vet all listings to ensure authenticity and quality, offering a secure platform for exploring nightlife and styling services.</p>
        <h2>Control Your Experience</h2>
        <ul class="white-bullet">
          <li>&nbsp;Report and Block: If you encounter inappropriate content or behavior, you have the tools to report or block posts or users. This helps us maintain a respectful and safe community</li>
          <li>&nbsp;Privacy Settings: Customize your privacy settings to control who can see your activities and personal information.</li>
          <li>&nbsp;Export Your Data: At any time, you can export your data for personal review or backup, ensuring you have full control over your information.</li>
        </ul>
        <br />
        <h2>Staying Informed</h2>
        <ul class="white-bullet">
          <li>&nbsp;Safety Tips: Learn best practices for staying safe online and within the SnapStay app.</li>
          <li>&nbsp;Updates on Safety Features: Stay informed about new safety features and updates designed to enhance your security on SnapStay.</li>
        </ul>
        <br />
      </div>

      <div style={{ marginTop: '-5px' }}>
        <svg viewBox="0 -80 700 110" width="100%" height="110" preserveAspectRatio="none">
          <path transform="translate(0, -20)" d="M0,10 c80,-22 240,0 350,18 c90,17 260,7.5 350,-20 v50 h-700" fill="#fff"></path>
        </svg>
      </div>

      <div style={{ backgroundColor: '#ffffff', marginTop: '-5px' }}>
        <br />
        <br />
        <div class="section2">
          <h2>FAQs and Guides</h2>
          <p class="section2">Find answers to common questions and detailed guides on using SnapStay safely. Whether you're looking to report a post or understand how to manage your privacy settings, our resources are here to help.</p>
          <br />
          <h2>How to reach us</h2>
          <p class="section2">We are always trying to make SnapStay better. If you have any feedback or want to report a negative experience, please reach out at <a href="mailto:patrick@snapstay.co">patrick@snapstay.co</a></p>
          <br />
          <br />
          <br />
        </div>
        <br />
        <br />
      </div>
    </div>
  );
}

export default Safety;